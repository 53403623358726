.main{
    background-color: #444;
    width: 100%;
    min-height: 100vh;
}

.flex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.display-cards{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.container-buttons-pages{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.page-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: sans-serif;
  border-radius: 6px;
  border: none;

  background: #222;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #DFDEDF;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.page-button:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

h1{
    display: block;
    font-size: 2em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
    max-width: 80%;
}

p{
    width: 80%;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-top: 20px;
}

ul li{
    color: white;
}

.comic-image{
    width: 250px;
    height: 400px;
    margin: 10px;
}