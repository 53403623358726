.comic-card{
    background-color: #222;
    border-radius: 8px;
    width: 400px;
    height: 300px;
    margin: 5px;
    color: white;
}

.comic-card-small{
  background-color: #222;
  border-radius: 8px;
  width: 170px;
  height: 320px;
  margin: 5px;
  color: white;
}

.data-card{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 280px;
}

.image-card{
    height: 260px;
    width: 160px;
    padding-left: 5px;
}

.info-card{
  height: 280px;
  width: 195px;
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-card-small{
  height: 55px;
  width: 160px;
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learn-more-button{
  background-color: transparent;
  color: #008CBA;
  padding-left: 5px;
}

h4{
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

p{
  margin: 0px;
  max-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

header{
  position: relative;
  width: 100%;
  display: flex;
  background: #16202c;
  box-shadow: 2px 2px 6px #000000;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  z-index: 10;
}

.toolbar{
  height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  position: relative;
  align-items: center;
}

.h1-header{
  color: white;
  font-size: 1.5rem;
}

.left-container-header{
  height: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.span-header{
  width: 100px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 2rem;
  align-items: center;
  flex-shrink: 0;
  font-family: inherit;
  line-height: 1;
  user-select: none;
  justify-content: center;
}

.logo{
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}